import type { App } from 'vue';

import i18n from '@/js/bootstrap/i18n';
import plugins from '@/js/bootstrap/plugins';
import components from '@/js/bootstrap/components';


export default async function bootstrap(app: App) {

    await i18n(app);
    plugins(app);
    components(app);
}
