import { Client } from "../Client";
import { AuthorizationDelegate } from "./AuthorizationDelegate";
import { CartDelegate } from "./CartDelegate";
import { CustomersDelegate } from "./CustomersDelegate";
import { CategoriesDelegate } from './CategoriesDelegate';
import { ItemsDelegate } from './ItemsDelegate';
import { PaymentsDelegate } from './PaymentsDelegate';
import { ReservationsDelegate } from './ReservationsDelegate';
import { WarehousesDelegate } from './WarehousesDelegate';
import { SerialsDelegate } from './SerialsDelegate';
import { SettingsDelegate } from './SettingsDelegate';
import { InvoicesDelegate } from './InvoicesDelegate';
import { CustomerItemsDelegate } from './CustomerItemsDelegate';
import { GeneralDelegate } from './GeneralDelegate';
import { ContactsDelegate } from './ContactsDelegate';
import { ConversationsDelegate } from './ConversationsDelegate';
import { KruispuntdatabankDelegate } from "./KruispuntdatabankDelegate";
import { CapacityDelegate } from './CapacityDelegate';
export class ShopClient extends Client {
    constructor() {
        super(...arguments);
        this._general = new GeneralDelegate(this._client);
        this._authorization = new AuthorizationDelegate(this._client);
        this._cart = new CartDelegate(this._client);
        this._customers = new CustomersDelegate(this._client);
        this._categories = new CategoriesDelegate(this._client);
        this._items = new ItemsDelegate(this._client);
        this._payments = new PaymentsDelegate(this._client);
        this._reservations = new ReservationsDelegate(this._client);
        this._warehouses = new WarehousesDelegate(this._client);
        this._serials = new SerialsDelegate(this._client);
        this._settings = new SettingsDelegate(this._client);
        this._invoices = new InvoicesDelegate(this._client);
        this._customerItems = new CustomerItemsDelegate(this._client);
        this._contacts = new ContactsDelegate(this._client);
        this._conversations = new ConversationsDelegate(this._client);
        this._kruispuntdatabank = new KruispuntdatabankDelegate(this._client);
        this._capacity = new CapacityDelegate(this._client);
    }
    get general() {
        return this._general;
    }
    get authorization() {
        return this._authorization;
    }
    get cart() {
        return this._cart;
    }
    get customers() {
        return this._customers;
    }
    get categories() {
        return this._categories;
    }
    get items() {
        return this._items;
    }
    get payments() {
        return this._payments;
    }
    get reservations() {
        return this._reservations;
    }
    get invoices() {
        return this._invoices;
    }
    get warehouses() {
        return this._warehouses;
    }
    get serials() {
        return this._serials;
    }
    get customerItems() {
        return this._customerItems;
    }
    get contacts() {
        return this._contacts;
    }
    get conversations() {
        return this._conversations;
    }
    get settings() {
        return this._settings;
    }
    get capacity() {
        return this._capacity;
    }
    get kruispuntdatabank() {
        return this._kruispuntdatabank;
    }
}
