import type { App } from 'vue';

import di from 'di';
import { shopPlugin } from '@rentmagic/shop-components';

import { OverlayPlugin, ToastPlugin, ToastType } from '@wittignl/js-ui';
import { GlobalPlugin, StoragePlugin, StylePlugin } from '@wittignl/js-core';


const dataDomain = document.currentScript?.getAttribute('data-domain') || window.RM_DOMAIN;
if (!dataDomain) {
    throw 'No RM domain set (data-domain)';
}

const baseURL = (!dataDomain.endsWith('/api')) ? `${dataDomain}/api` : dataDomain;


export default function (app: App) {

    // Core
    app.use(new OverlayPlugin());
    app.use(new StoragePlugin());
    app.use(new StylePlugin());
    app.use(GlobalPlugin, {
        $di: di
    });

    // Store
    // app.use(store);

    // Toast types
    const toastPlugin = new ToastPlugin()
        .type(ToastType.POSITIVE, 'ph ph-check-circle', ['positive'])
        .type(ToastType.NEGATIVE, 'ph ph-warning-circle', ['negative'])
        .type(ToastType.WARNING, 'ph ph-warning', ['warning'])
        .type(ToastType.NOTICE, 'ph ph-info', ['notice']);

    app.use(toastPlugin, { i18n: di.get('i18n').global });

    app.use(shopPlugin, {

        baseURL,
        i18n: di.get('i18n')
    });
}
