import { ClientDelegate } from '../ClientDelegate';
import { parseCategoryEntity, parseItemPropertyEntity, parseItemPropertyGroupEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/categories';
export class CategoriesDelegate extends ClientDelegate {
    async all() {
        const response = await this._client.get(`v2/${PATH}`);
        return parseCollection(response.data, parseCategoryEntity);
    }
    async find(categoryId) {
        var _a;
        const response = await this._client.get(`v2/${PATH}/${categoryId}`);
        return parseCategoryEntity((_a = response.data) === null || _a === void 0 ? void 0 : _a.Category);
    }
    async itemProperties(categoryId) {
        var _a;
        const response = await this._client.get(`v2/${PATH}/${categoryId}/item-properties`);
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.map(item => parseItemPropertyEntity(item));
    }
    async itemPropertiesGroups(categoryId) {
        var _a;
        const response = await this._client.get(`v2/${PATH}/${categoryId}/item-properties/groups`);
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.map(item => parseItemPropertyGroupEntity(item));
    }
    async default() {
        var _a;
        const response = await this._client.get(`v2/${PATH}/default`);
        return parseCategoryEntity((_a = response.data) === null || _a === void 0 ? void 0 : _a.Category);
    }
}
