import { parseTokenEntity } from "./TokenEntity";
import { parseAccountEntity } from './AccountEntity';
export function parseLoginEntity(data) {
    var _a;
    if (!data) {
        return null;
    }
    return {
        Accounts: (_a = data.Accounts) === null || _a === void 0 ? void 0 : _a.map(item => parseAccountEntity(item)),
        Token: parseTokenEntity(data.Token)
    };
}
