import { compactObject } from '../common';
import { transformDate } from './common';
export function transformCartItemInput(input) {
    return Object.assign(Object.assign({}, input), compactObject({
        DateTimeBusinessStart: transformDate(input.DateTimeBusinessStart),
        DateTimeBusinessEnd: transformDate(input.DateTimeBusinessEnd),
        DateTimeExpectedStart: transformDate(input.DateTimeExpectedStart),
        DateTimeExpectedEnd: transformDate(input.DateTimeExpectedEnd)
    }));
}
