export class RentMagicError extends Error {
    constructor(response, message) {
        super(message);
        this.name = "RentMagicError";
        this.response = response;
        this.message = message;
    }
}
export class InvalidResponseError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "InvalidResponseError";
    }
}
export class TokenMissingError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "TokenMissingError";
    }
}
export class UnauthorizedError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "UnauthorizedError";
    }
}
export class IncorrectDetailsError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "IncorrectDetails";
    }
}
export class UserNotApprovedError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "UserNotApproved";
    }
}
export class UserLockedOutError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "UserLockedOut";
    }
}
export class UserDuplicateError extends RentMagicError {
    constructor() {
        super(...arguments);
        this.name = "UserDuplicateError";
    }
}
export function getErrorByResponse(response) {
    var _a;
    const data = response === null || response === void 0 ? void 0 : response.data;
    let message = (_a = data === null || data === void 0 ? void 0 : data.Error) === null || _a === void 0 ? void 0 : _a.Message;
    if (!message) {
        return null;
    }
    switch (message) {
        case "No Bearer authentication Token found.":
            return new TokenMissingError(data);
        case "IncorrectDetails":
            return new IncorrectDetailsError(data);
        case "UserNotApproved":
            return new UserNotApprovedError(data);
        case "UserLockedOut":
            return new UserLockedOutError(data);
    }
    switch (response.status) {
        case 409:
            return new UserDuplicateError(data);
        case 401:
            return new UnauthorizedError(data);
        default:
            return new InvalidResponseError(data);
    }
}
