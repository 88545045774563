import { ClientDelegate } from '../ClientDelegate';
import { parseReservationEntity, parseReservationItemEntity } from '../../entity';
import { parseCollection, transformIncludeOptions } from '../../common';
const PATH = 'shop/reservationOrders';
export class ReservationsDelegate extends ClientDelegate {
    async all(options) {
        const response = await this._client.get(`v2/${PATH}`, {
            params: Object.assign(Object.assign({}, options), { includeContactId: false, includeCustomerId: true }),
        });
        return parseCollection(response.data, parseReservationEntity);
    }
    async find(reservationId) {
        const response = await this._client.get(`v2/${PATH}/${reservationId}`);
        const result = response.data;
        return parseReservationEntity(result);
    }
    async cancel(reservationId) {
        const response = await this._client.post(`v2/${PATH}/${reservationId}/cancel`, {});
        return response.data;
    }
    async items(reservationId, options) {
        const response = await this._client.get(`v2/${PATH}/${reservationId}/items`, {
            params: transformIncludeOptions(options)
        });
        return parseCollection(response.data, parseReservationItemEntity);
    }
    async validateUpdate(reservationId, data) {
        const response = await this._client.post(`v2/${PATH}/${reservationId}/_validate-update`, data);
        return response.data;
    }
    async update(reservationId, data) {
        const response = await this._client.post(`v2/${PATH}/${reservationId}/_update`, data);
        return response.data;
    }
    async attachment(reservationId, templateId, filename, token) {
        const response = await this._client.get(`v2/${PATH}/${reservationId}/documents/${templateId}/${token}/${filename}`, {
            responseType: 'blob'
        });
        return response.data;
    }
}
