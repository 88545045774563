import { ClientDelegate } from '../ClientDelegate';
import { parseConversationAttachmentEntity, parseConversationEntity, parseConversationMessageEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/customer/conversation';
export class ConversationsDelegate extends ClientDelegate {
    async all(customerId, includes) {
        const response = await this._client.get(`v2/${PATH}/${customerId}`, {
            params: {
                include: (includes === null || includes === void 0 ? void 0 : includes.join(',')) || null,
                sortOn: 'DateTimeCreated'
            }
        });
        return parseCollection(response.data, parseConversationEntity);
    }
    async rentalObject(customerId, rentalObjectId, includes) {
        const response = await this._client.get(`v2/${PATH}/${customerId}/${rentalObjectId}`, {
            params: {
                include: (includes === null || includes === void 0 ? void 0 : includes.join(',')) || null
            }
        });
        return parseCollection(response.data, parseConversationEntity);
    }
    async create(customerId, data) {
        const response = await this._client.post(`v2/${PATH}/${customerId}`, data);
        const result = response.data;
        return parseConversationEntity(result);
    }
    async createMessage(customerId, conversationId, data) {
        const response = await this._client.post(`v2/${PATH}/${customerId}/${conversationId}/communication`, data);
        const result = response.data;
        return parseConversationMessageEntity(result);
    }
    async createAttachment(customerId, conversationId, communicationId, data) {
        const response = await this._client.post(`v2/shop/customer/conversation-attachment/${customerId}/${conversationId}/${communicationId}`, data);
        const result = response.data;
        return parseConversationAttachmentEntity(result);
    }
}
