import { ClientDelegate } from '../ClientDelegate';
import { parseContactEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/contacts';
export class ContactsDelegate extends ClientDelegate {
    async all(customerId) {
        const response = await this._client.get(`v2/${PATH}`, {
            params: {
                customerID: customerId
            }
        });
        return parseCollection(response.data, parseContactEntity);
    }
    async find(contactId) {
        const response = await this._client.get(`v2/${PATH}/${contactId}`);
        const result = response.data;
        return parseContactEntity(result);
    }
    async update(contactId, data) {
        const response = await this._client.patch(`v2/${PATH}/${contactId}`, data);
        const result = response.data;
        return parseContactEntity(result);
    }
}
