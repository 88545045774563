import { ClientDelegate } from '../ClientDelegate';
import { parseCustomerItemSerialEntity, parseSerialAttachmentEntity } from '../../entity';
import { parseCollection } from '../../common';
export class CustomerItemsDelegate extends ClientDelegate {
    async serials(options) {
        const response = await this._client.get(`v2/shop/customer/customeritemserials`, {
            params: options,
            responseType: options.format ? 'blob' : 'json'
        });
        return parseCollection(response.data, parseCustomerItemSerialEntity, options);
    }
    async attachments(options) {
        const response = await this._client.get(`v2/shop/customer/${options.customerId}/customeritems/${options.itemId}/serials/${options.serialId}/attachments`, {
            params: options,
            responseType: options.format ? 'blob' : 'json'
        });
        return parseCollection(response.data, parseSerialAttachmentEntity, options);
    }
    async attachmentsZip(customerId) {
        const response = await this._client.get(`v2/shop/customer/${customerId}/customeritems/serials/attachments/_zip`);
        return response.data;
    }
}
