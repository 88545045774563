import axios from 'axios';
import { resolveProvider } from "../common";
import { getErrorByResponse, UnauthorizedError } from '../error';
export class Client {
    constructor(options) {
        this._options = options;
        this._updateComputed();
        const client = axios.create(this._getAxiosConfig(options));
        client.interceptors.request.use(this._interceptRequest.bind(this));
        client.interceptors.response.use((response) => this._interceptResponse(response), (error) => {
            this._interceptResponse(error.response);
            return Promise.reject(error);
        });
        this._client = client;
    }
    setOptions(options) {
        this._options = Object.assign(Object.assign({}, this._options), options);
        this._updateComputed();
        const axiosConfig = this._getAxiosConfig(this._options);
        for (let key of Object.keys(axiosConfig)) {
            this._client.defaults[key] = axiosConfig[key];
        }
    }
    get client() {
        return this._client;
    }
    resolvePath(path) {
        return this._urlBase + path;
    }
    async _interceptRequest(request) {
        if (this._options.accessToken) {
            const token = await resolveProvider(this._options.accessToken);
            if (token) {
                request.headers.Authorization = `Bearer ${token}`;
            }
        }
        if (this._options.language) {
            const language = await resolveProvider(this._options.language);
            if (language) {
                request.headers['Accept-Language'] = language;
            }
        }
        if (this._options.contactId) {
            // check if params exist otherwise create   
            if (!request.params) {
                request.params = {};
            }
            const contactId = await resolveProvider(this._options.contactId);
            if (!request.params.ContactId && contactId && request.params.includeContactId) {
                request.params.ContactID = contactId;
            }
            delete request.params.includeContactId;
        }
        if (this._options.customerId) {
            if (!request.params) {
                request.params = {};
            }
            const customerId = await resolveProvider(this._options.customerId);
            if (!request.params.CustomerId && customerId && request.params.includeCustomerId) {
                request.params.CustomerID = customerId;
            }
            delete request.params.includeCustomerId;
        }
        return request;
    }
    _interceptResponse(response) {
        const clientError = getErrorByResponse(response);
        if (clientError) {
            if (clientError instanceof UnauthorizedError && this._options.onUnauthorized) {
                this._options.onUnauthorized(response);
            }
            else {
                throw clientError;
            }
        }
        return response;
    }
    _getAxiosConfig(options) {
        return {
            baseURL: options.baseURL
        };
    }
    _updateComputed() {
        const url = new URL(this._options.baseURL);
        this._urlBase = `${url.protocol}//${url.hostname}`;
    }
}
