import { parseCollection, transformIncludeOptions } from '../../common';
import { ClientDelegate } from '../ClientDelegate';
import { transformCartInput, transformCartItemInput, transformCheckoutAnonymousInput, transformCheckoutInput, transformCheckoutRegisterInput, transformInvalidCartItemsInput } from '../../input';
import { parseCartEntity, parseCartItemEntity, parseReservationEntity, } from '../../entity';
const PATH = 'rental/cart';
export class CartDelegate extends ClientDelegate {
    async get(cartId, options) {
        const response = await this._client.get(`v3/${PATH}/${cartId}?include=actions`, {
            params: transformIncludeOptions(options)
        });
        const result = response.data;
        return parseCartEntity(result);
    }
    async create(input, options) {
        const response = await this._client.post(`v3/${PATH}`, transformCartInput(input), {
            params: options
        });
        const result = response.data;
        return parseCartEntity(result);
    }
    async update(cartId, input) {
        const response = await this._client.patch(`v3/${PATH}/${cartId}`, transformCartInput(input));
        const result = response.data;
        return parseCartEntity(result);
    }
    async delete(cartId) {
        const response = await this._client.delete(`v3/${PATH}/${cartId}`);
        return response.data;
    }
    async copyCart(cartId, reservationOrderId) {
        const response = await this._client.post(`v3/${PATH}/${cartId}/copy/${reservationOrderId}`);
        return response.data;
    }
    async copy(anonymousToken, options) {
        const response = await this._client.post(`v3/${PATH}/_copy/${anonymousToken}`, null, {
            params: Object.assign(Object.assign({}, options), { includeContactId: true, includeCustomerId: true })
        });
        return parseCartEntity(response.data);
    }
    async items(cartId, options) {
        const response = await this._client.get(`v3/${PATH}/${cartId}/items`, {
            params: Object.assign(Object.assign({}, transformIncludeOptions(options)), { includeContactId: false, includeCustomerId: true }),
        });
        return parseCollection(response.data, parseCartItemEntity);
    }
    async addItems(cartId, items) {
        const response = await this._client.post(`v3/${PATH}/${cartId}/items`, items.map(item => transformCartItemInput(item)));
        const result = response.data;
        return parseCartEntity(result);
    }
    async replaceItems(cartId, items) {
        const response = await this._client.put(`v3/${PATH}/${cartId}/items`, items.map(item => transformCartItemInput(item)));
        const result = response.data;
        return parseCartEntity(result);
    }
    async updateItem(cartId, cartItemId, input) {
        const response = await this._client.patch(`v3/${PATH}/${cartId}/items/${cartItemId}`, transformCartItemInput(input));
        const result = response.data;
        return parseCartItemEntity(result);
    }
    async deleteItem(cartId, cartItemId) {
        const response = await this._client.delete(`v3/${PATH}/${cartId}/items/${cartItemId}`);
        return response.data;
    }
    async deleteItemAttachment(cartId, cartItemId, attachmentId) {
        const response = await this._client.delete(`v3/${PATH}/${cartId}/items/${cartItemId}/attachment/${attachmentId}`);
        return response.data;
    }
    async checkout(cartId, input) {
        const response = await this._client.post(`v3/rental/cart/${cartId}/_checkout`, transformCheckoutInput(input));
        return parseCollection(response.data, parseReservationEntity);
    }
    async checkoutAnonymous(cartId, input) {
        const response = await this._client.post(`v3/rental/cart/${cartId}/_checkout-anonymous`, transformCheckoutAnonymousInput(input));
        return parseCollection(response.data, parseReservationEntity);
    }
    async checkoutRegister(cartId, input) {
        const response = await this._client.post(`v3/rental/cart/${cartId}/_checkout-and-register`, transformCheckoutRegisterInput(input));
        return parseCollection(response.data, parseReservationEntity);
    }
    async invalidCartItems(cartId, items, warehouseId) {
        const transformedData = items.map(itm => transformInvalidCartItemsInput(itm));
        const response = await this._client.post(`v3/rental/cart/${cartId}/items/_conflict-check`, transformedData, {
            params: {
                warehouse: warehouseId
            }
        });
        return response.data.map(item => parseCartItemEntity(item));
    }
    async submitCertificate(cartId, data) {
        const response = await this._client.post(`v3/rental/cart/${cartId}/_certificate`, data);
        return response.data;
    }
    async cartV2() {
        const response = await this._client.get(`v2/shop/cart`, {
            params: {
                includeContactId: true,
                includeCustomerId: true
            }
        });
        return response.data;
    }
}
