import { ClientDelegate } from '../ClientDelegate';
import { parsePaymentStatusEntity } from '../../entity';
const PATH = 'shop/payments';
export class PaymentsDelegate extends ClientDelegate {
    async link(options) {
        const response = await this._client.get(`v2/${PATH}/link`, {
            params: options
        });
        const result = response.data;
        return parsePaymentStatusEntity(result);
    }
}
