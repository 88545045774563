import { ClientDelegate } from '../ClientDelegate';
import { parseCollection } from '../../common';
import { parseContactEntity } from '../../entity';
export class GeneralDelegate extends ClientDelegate {
    async contactForm(input) {
        const response = await this._client.post(`v2/_contact-form`, input);
        return response.data;
    }
    /**
     *
     * @param customerId
     * @deprecated Replaced by contacts.all()
     */
    async contacts(customerId) {
        const response = await this._client.get(`v2/shop/contacts`, {
            params: {
                customerID: customerId
            }
        });
        return parseCollection(response.data, parseContactEntity);
    }
    async labels(tableName) {
        const response = await this._client.get(`v2/shop/label?subject=${tableName}`);
        return response.data.Collection;
    }
}
