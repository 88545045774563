export var ReservationStatus;
(function (ReservationStatus) {
    ReservationStatus["ACTIVE"] = "Active";
    ReservationStatus["ORDER"] = "Order";
    ReservationStatus["EXPIRED"] = "Expired";
    ReservationStatus["REJECTED"] = "Rejected";
    ReservationStatus["CANCELLED"] = "Cancelled";
    ReservationStatus["DRAFT"] = "Draft";
    ReservationStatus["OPEN"] = "Open";
    ReservationStatus["PICK"] = "Pick";
    ReservationStatus["PICKED"] = "Picked";
    ReservationStatus["CLOSED"] = "Closed";
    ReservationStatus["CLOSED_AND_COMPLETED"] = "ClosedAndCompleted";
})(ReservationStatus || (ReservationStatus = {}));
