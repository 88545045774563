import { isString } from 'lodash';


export function getVar(name: string) {

    const value = getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);
    const result = isString(value) ? value.trim() : value;

    return result?.replace(/['"]+/g, '');
}
