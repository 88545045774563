import type { App } from 'vue';

import di from 'di';
import { Settings } from 'luxon';
import { createI18n } from 'vue-i18n';
import { first, merge } from 'lodash';
import { i18n as componentMessages, resolvePathParam } from '@rentmagic/shop-components';

import { i18n as uiMessages } from '@wittignl/js-ui';
import { i18n as coreMessages } from '@wittignl/js-core';

import { getVar } from '../util/var';


export default async function (app: App) {

    // Fetch messages
    const i18nFileUrl = document.currentScript?.getAttribute('data-i18n');

    let i18nFile = null;

    if (i18nFileUrl) {

        const i18nData = await fetch(i18nFileUrl);
        i18nFile = await i18nData.json();
    }

    // Auto-select language
    let supportedLanguages = Object.keys(componentMessages);
    if (i18nFile) {
        supportedLanguages = Object.keys(i18nFile);
    }

    const defaultLanguage = 'nl';

    let locale = navigator.language ? first(navigator.language.split('-')) : defaultLanguage;

    const localeVar = getVar('rm-locale');
    const patternVar = getVar('rm-url-pattern-locale');

    if (localeVar) {
        locale = localeVar.toLowerCase();
    }
    else if (patternVar) {

        const pathLocale = resolvePathParam('locale', patternVar, false);
        if (pathLocale) {
            locale = pathLocale.toLowerCase();
        }
    }

    if (supportedLanguages.indexOf(locale) < 0) {
        locale = defaultLanguage;
    }

    const messages = merge({}, coreMessages, uiMessages, componentMessages, i18nFile);

    // I18n
    const i18n = createI18n({
        locale,
        fallbackLocale: defaultLanguage,
        messages,
        numberFormats: messages
    });

    // Luxon
    Settings.defaultLocale = locale;

    di.set('i18n', i18n);
    app.use(i18n);
}
