import { createApp } from 'vue';

import '@/scss/main.scss';

import bootstrap from './bootstrap';
import App from './component/App.vue';


async function run() {

    const app = createApp(App);

    // Bootstrap
    await bootstrap(app);

    app.mount('#rm-app');
}

run();
