import { ClientDelegate } from '../ClientDelegate';
export class KruispuntdatabankDelegate extends ClientDelegate {
    async enterpriseData(enterpriseNumber) {
        const response = await this._client.get(`v2/kruispuntdatabank/enterprise/${enterpriseNumber}`);
        return response.data.Enterprise;
    }
    async enterpriseDataAddress(enterpriseNumber) {
        const response = await this._client.get(`v2/kruispuntdatabank/enterprise/${enterpriseNumber}/address`);
        return response.data;
    }
    async establishmentData(establishmentNumber) {
        const response = await this._client.get(`v2/kruispuntdatabank/establishment/${establishmentNumber}`);
        return response.data;
    }
    async establishmentDataAddress(establishmentNumber) {
        const response = await this._client.get(`v2/kruispuntdatabank/establishment/${establishmentNumber}/address`);
        return response.data;
    }
}
