import { parseLabelEntity } from './LabelEntity';
import { parseItemItemEntity } from './ItemItemEntity';
import { parsePeriodPriceEntity } from './PeriodPriceEntity';
export function parseItemEntity(data) {
    var _a, _b, _c;
    if (!data) {
        return null;
    }
    return Object.assign(Object.assign({}, data), { PeriodPrices: (_a = data.PeriodPrices) === null || _a === void 0 ? void 0 : _a.map(item => parsePeriodPriceEntity(item)), Label: (_b = data.Label) === null || _b === void 0 ? void 0 : _b.map(item => parseLabelEntity(item)), Items: (_c = data.Items) === null || _c === void 0 ? void 0 : _c.map(item => parseItemItemEntity(item)) });
}
