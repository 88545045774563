<template>
    <teleport v-if="teleports.cartButton?.enabled" to="#rm-cart-button">
        <rm-cart-button v-bind="teleports.cartButton.attrs" />
    </teleport>

    <teleport v-if="teleports.locationPeriodSelector?.enabled" to="#rm-location-period-selector">
        <rm-location-period-selector v-bind="teleports.locationPeriodSelector.attrs" />
    </teleport>

    <teleport v-if="teleports.productSearchbar?.enabled" to="#rm-product-searchbar">
        <rm-product-searchbar v-bind="teleports.productSearchbar.attrs" />
    </teleport>

    <teleport v-if="teleports.categoryGrid?.enabled" to="#rm-category-grid">
        <rm-category-grid v-bind="teleports.categoryGrid.attrs" />
    </teleport>

    <teleport v-if="teleports.productGrid?.enabled" to="#rm-product-grid">
        <rm-product-grid v-bind="teleports.productGrid.attrs" />
    </teleport>

    <teleport v-if="teleports.productFilterBar?.enabled" to="#rm-product-filter-bar">
        <rm-product-filter-bar v-bind="teleports.productFilterBar.attrs" />
    </teleport>

    <teleport v-if="teleports.product?.enabled" to="#rm-product">
        <rm-product v-bind="teleports.product.attrs" />
    </teleport>

    <teleport v-if="teleports.productDetails?.enabled" to="#rm-product-details">
        <rm-product-details v-bind="teleports.productDetails.attrs" />
    </teleport>

    <teleport v-if="teleports.productMedia?.enabled" to="#rm-product-media">
        <rm-product-media v-bind="teleports.productMedia.attrs" />
    </teleport>

    <teleport v-if="teleports.productSuggestions?.enabled" to="#rm-product-suggestions">
        <rm-product-suggestions v-bind="teleports.productSuggestions.attrs" />
    </teleport>

    <teleport v-if="teleports.productPeriodPrices?.enabled" to="#rm-product-period-prices">
        <rm-product-period-prices v-bind="teleports.productPeriodPrices.attrs" />
    </teleport>

    <teleport v-if="teleports.productConfigurator?.enabled" to="#rm-product-configurator">
        <rm-product-configurator v-bind="teleports.productConfigurator.attrs" />
    </teleport>

    <teleport v-if="teleports.productSerialRedirector?.enabled" to="#rm-product-serial-redirector">
        <rm-product-serial-redirector v-bind="teleports.productSerialRedirector.attrs" />
    </teleport>

    <teleport v-if="teleports.cartDetails?.enabled" to="#rm-cart-details">
        <rm-cart-details v-bind="teleports.cartDetails.attrs" />
    </teleport>

    <teleport v-if="teleports.orderSummary?.enabled" to="#rm-order-summary">
        <rm-order-summary v-bind="teleports.orderSummary.attrs" />
    </teleport>

    <teleport v-if="teleports.login?.enabled" to="#rm-login">
        <rm-login v-bind="teleports.login.attrs" />
    </teleport>

    <teleport v-if="teleports.forgotPassword?.enabled" to="#rm-forgot-password">
        <rm-forgot-password v-bind="teleports.forgotPassword.attrs" />
    </teleport>

    <teleport v-if="teleports.resetPassword?.enabled" to="#rm-reset-password">
        <rm-reset-password v-bind="teleports.resetPassword.attrs" />
    </teleport>

    <teleport v-if="teleports.register?.enabled" to="#rm-register">
        <rm-register v-bind="teleports.register.attrs" />
    </teleport>

    <teleport v-if="teleports.account?.enabled" to="#rm-account">
        <rm-account v-bind="teleports.account.attrs" />
    </teleport>

    <teleport v-if="teleports.accountOrderDetails?.enabled" to="#rm-account-order-details">
        <rm-account-order-details v-bind="teleports.accountOrderDetails.attrs" />
    </teleport>

    <teleport v-if="teleports.accountButton?.enabled" to="#rm-account-button">
        <rm-account-button v-bind="teleports.accountButton.attrs" />
    </teleport>

    <teleport v-if="teleports.productDetailPage?.enabled" to="#rm-product-detail-page">
        <rm-product-detail-page v-bind="teleports.productDetailPage.attrs" />
    </teleport>

    <teleport v-if="teleports.payment?.enabled" to="#rm-payment">
        <rm-payment v-bind="teleports.payment.attrs" />
    </teleport>

    <teleport v-if="teleports.activateAccount?.enabled" to="#rm-activate-account">
        <rm-activate-account v-bind="teleports.activateAccount.attrs" />
    </teleport>

    <teleport v-if="teleports.logoutButton?.enabled" to="#rm-logout-button">
        <rm-logout-button v-bind="teleports.logoutButton.attrs" />
    </teleport>

    <teleport v-if="teleports.conversations?.enabled" to="#rm-conversations">
        <rm-conversations v-bind="teleports.conversations.attrs" />
    </teleport>

    <teleport v-if="teleports.productAddons?.enabled" to="#rm-product-addons">
        <rm-product-addons v-bind="teleports.productAddons.attrs" />
    </teleport>

    <ui-toast-container />
    <ui-overlay-container />
</template>

<script lang="ts">
import { mapValues } from 'lodash';
import { Component, Vue } from 'vue-facing-decorator';
import { StoreAction } from '@rentmagic/shop-components';


@Component({
    name: 'App'
})
export default class App extends Vue {

    teleports = {
        activateAccount: null,
        cartButton: null,
        conversations: null,
        logoutButton: null,
        locationPeriodSelector: null,
        productAddons: null,
        product: null,
        productSearchbar: null,
        categoryGrid: null,
        productGrid: null,
        productFilterBar: null,
        productDetails: null,
        productMedia: null,
        productSuggestions: null,
        productPeriodPrices: null,
        productConfigurator: null,
        productSerialRedirector: null,
        cartDetails: null,
        orderSummary: null,
        login: null,
        forgotPassword: null,
        resetPassword: null,
        register: null,
        account: null,
        accountOrderDetails: null,
        accountButton: null,
        productDetailPage: null,
        payment: null
    };

    initTeleports() {

        this.teleports = {
            activateAccount: this.mapTeleportationTarget('rm-activate-account'),
            account: this.mapTeleportationTarget('rm-account'),
            accountButton: this.mapTeleportationTarget('rm-account-button'),
            accountOrderDetails: this.mapTeleportationTarget('rm-account-order-details'),
            cartButton: this.mapTeleportationTarget('rm-cart-button'),
            cartDetails: this.mapTeleportationTarget('rm-cart-details'),
            categoryGrid: this.mapTeleportationTarget('rm-category-grid'),
            conversations: this.mapTeleportationTarget('rm-conversations'),
            forgotPassword: this.mapTeleportationTarget('rm-forgot-password'),
            locationPeriodSelector: this.mapTeleportationTarget('rm-location-period-selector'),
            login: this.mapTeleportationTarget('rm-login'),
            logoutButton: this.mapTeleportationTarget('rm-logout-button'),
            orderSummary: this.mapTeleportationTarget('rm-order-summary'),
            payment: this.mapTeleportationTarget('rm-payment'),
            product: this.mapTeleportationTarget('rm-product'),
            productAddons: this.mapTeleportationTarget('rm-product-addons'),
            productConfigurator: this.mapTeleportationTarget('rm-product-configurator'),
            productDetailPage: this.mapTeleportationTarget('rm-product-detail-page'),
            productDetails: this.mapTeleportationTarget('rm-product-details'),
            productFilterBar: this.mapTeleportationTarget('rm-product-filter-bar'),
            productGrid: this.mapTeleportationTarget('rm-product-grid'),
            productMedia: this.mapTeleportationTarget('rm-product-media'),
            productPeriodPrices: this.mapTeleportationTarget('rm-product-period-prices'),
            productSearchbar: this.mapTeleportationTarget('rm-product-searchbar'),
            productSerialRedirector: this.mapTeleportationTarget('rm-product-serial-redirector'),
            productSuggestions: this.mapTeleportationTarget('rm-product-suggestions'),
            register: this.mapTeleportationTarget('rm-register'),
            resetPassword: this.mapTeleportationTarget('rm-reset-password')
        };
    }

    mapTeleportationTarget(targetId) {

        const target = document.getElementById(targetId);

        let dataSet: Record<string, any> = { ...target?.dataset };

        // Transform booleans
        dataSet = mapValues(dataSet, (itm) => {

            if (itm === 'true') {
                return true;
            }
            else if (itm === 'false') {
                return false;
            }

            return itm;
        });

        return {

            enabled: target != null,
            attrs: dataSet
        };
    }

    async mounted() {

        this.setup();
    }

    async setup() {

        try {

            await this.$store.dispatch(StoreAction.SETUP);
        }
        catch (e) {

            console.error('Error getting anonymous token', e);
        }

        this.initTeleports();
    }
}
</script>
