import { ClientDelegate } from '../ClientDelegate';
export class CapacityDelegate extends ClientDelegate {
    async capacity(options) {
        const response = await this._client.get(`v2/shop/capacity`, {
            params: options
        });
        return response.data;
    }
    async availableStartDays(options) {
        const response = await this._client.get(`v2/shop/capacity/_available-start-days`, {
            params: options
        });
        return response.data;
    }
    async availableEndDays(options) {
        const response = await this._client.get(`v2/shop/capacity/_available-end-days`, {
            params: options
        });
        return response.data;
    }
    async startBusinessMoments(options) {
        const response = await this._client.get(`v2/shop/capacity/_start-business-moments`, {
            params: options
        });
        return response.data;
    }
    async endBusinessMoments(options) {
        const response = await this._client.get(`v2/shop/capacity/_end-business-moments`, {
            params: options
        });
        return response.data;
    }
}
