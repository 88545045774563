import { ClientDelegate } from '../ClientDelegate';
import { transformAccountInput, transformCustomerDetailInput, transformCustomerInput, transformRegisterInput } from '../../input';
import { parseAccountEntity, parseCustomerEntity, parseCustomerDetailEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/customers';
export class CustomersDelegate extends ClientDelegate {
    async get(customerId) {
        const response = await this._client.get(`v3/customer/customer/${customerId}`);
        return parseCustomerEntity(response.data);
    }
    async getDetail(customerId) {
        const response = await this._client.get(`v3/customer/customer/${customerId}/detail`);
        return parseCustomerDetailEntity(response.data);
    }
    async update(customerId, input) {
        var _a;
        const response = await this._client.patch(`v2/${PATH}/${customerId}`, transformCustomerInput(input));
        return parseCustomerEntity((_a = response.data) === null || _a === void 0 ? void 0 : _a.Customer);
    }
    async updateDetail(customerId, input) {
        const response = await this._client.patch(`v2/${PATH}/${customerId}/detail`, transformCustomerDetailInput(input));
        return parseCustomerDetailEntity(response.data);
    }
    async register(input) {
        const response = await this._client.post(`v3/customer/customer/_register`, transformRegisterInput(input));
        // TODO
        return response.data;
    }
    async getPredefinedRegistration(key) {
        const response = await this._client.get(`v3/customer/customer/_register/${key}`);
        return response.data;
    }
    async registerWithKey(key, input) {
        const response = await this._client.post(`v3/customer/customer/_register/${key}`, transformRegisterInput(input));
        return response.data;
    }
    async registerMultipleContacts(input) {
        const response = await this._client.post(`v3/customer/customer/_register-multiple-contacts`, transformRegisterInput(input));
        return response.data;
    }
    async shopUsers(customerId) {
        const response = await this._client.get(`v3/customer/customer/${customerId}/shop-users`);
        return parseCollection(response.data, parseAccountEntity);
    }
    async createShopUser(customerId, input, options) {
        const response = await this._client.post(`v3/customer/customer/${customerId}/shop-users`, transformAccountInput(input), {
            params: options
        });
        return parseAccountEntity(response.data);
    }
    async inviteShopUser(customerId, contactId, input, options) {
        const response = await this._client.post(`v3/customer/customer/${customerId}/shop-users/${contactId}`, transformAccountInput(input), {
            params: options
        });
        return parseAccountEntity(response.data);
    }
    //! When using this method: UserName must ALWAYS be in sync with Email: #TICKETS-9919
    async updateShopUser(customerId, contactId, input) {
        const response = await this._client.patch(`v3/customer/customer/${customerId}/shop-users/${contactId}`, transformAccountInput(input));
        return parseAccountEntity(response.data);
    }
    async deleteShopUser(customerId, contactId) {
        const response = await this._client.delete(`v3/customer/customer/${customerId}/shop-users/${contactId}`);
        return response.data;
    }
}
