export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["APPLEPAY"] = "APPLEPAY";
    PaymentMethod["BANCONTACT"] = "BANCONTACT";
    PaymentMethod["CREDITCARD"] = "CREDITCARD";
    PaymentMethod["DIRECTDEBIT"] = "DIRECTDEBIT";
    PaymentMethod["GIROPAY"] = "GIROPAY";
    PaymentMethod["IDEAL"] = "IDEAL";
    PaymentMethod["KLARNA"] = "KLARNA";
    PaymentMethod["MASTERCARD"] = "MASTERCARD";
    PaymentMethod["PAYPAL"] = "PAYPAL";
    PaymentMethod["SOFORT"] = "SOFORT";
    PaymentMethod["VISA"] = "VISA";
})(PaymentMethod || (PaymentMethod = {}));
