import { parseCollection } from '../../common';
import { ClientDelegate } from "../ClientDelegate";
import { parseAccountEntity, parseLoginEntity, parseTokenEntity } from '../../entity';
import { transformAccountInput, transformLostPasswordInput, transformSetPasswordInput } from "../../input";
const PATH = 'shop/authorization';
export class AuthorizationDelegate extends ClientDelegate {
    async anonymousToken() {
        const response = await this._client.post(`v3/core/token/_get-anonymous-token`);
        return parseTokenEntity(response.data);
    }
    async login(username, password) {
        const response = await this._client.post(`v3/core/token/_login`, {
            UserName: username,
            Password: password
        });
        const result = response.data;
        return parseLoginEntity(result);
    }
    async user() {
        const response = await this._client.get(`v2/${PATH}/user`);
        const result = response.data;
        return parseLoginEntity(result);
    }
    async userInfo() {
        const response = await this._client.get(`v3/core/token/_user-info`);
        const result = response.data;
        return parseLoginEntity(result);
    }
    async confirmUser(code) {
        const response = await this._client.post(`v2/${PATH}/confirmuser`, null, {
            params: {
                code
            }
        });
        return response.data;
    }
    async lostPassword(input) {
        const response = await this._client.post(`v2/${PATH}/lostpassword`, transformLostPasswordInput(input));
        // TODO
        return response.data;
    }
    async setPassword(input) {
        const response = await this._client.post(`v2/${PATH}/setpassword`, transformSetPasswordInput(input));
        // TODO
        return response.data;
    }
    async subAccounts(contactId) {
        const response = await this._client.get(`v2/${PATH}/${contactId}/subaccounts`);
        // TODO
        return parseCollection(response.data, parseAccountEntity);
    }
    async createSubAccount(contactId, input) {
        var _a;
        const response = await this._client.post(`v2/${PATH}/${contactId}/subaccounts`, transformAccountInput(input));
        // TODO
        return parseAccountEntity((_a = response.data) === null || _a === void 0 ? void 0 : _a.Account);
    }
    async updateSubAccount({ contactId, targetContactId, userName }, input) {
        var _a;
        const response = await this._client.patch(`v2/${PATH}/${contactId}/subaccounts/${encodeURIComponent(userName)}/${targetContactId}`, transformAccountInput(input));
        // TODO
        return parseAccountEntity((_a = response.data) === null || _a === void 0 ? void 0 : _a.Account);
    }
    async deleteSubAccount({ contactId, targetContactId, userName }) {
        const response = await this._client.delete(`v2/${PATH}/${contactId}/subaccounts/${encodeURIComponent(userName)}/${targetContactId}`);
        return response.data;
    }
    async promoteSubAccount({ contactId, targetContactId, userName }) {
        const response = await this._client.post(`v2/${PATH}/${contactId}/subaccounts/${encodeURIComponent(userName)}/${targetContactId}/promote`);
        return response.data;
    }
}
