import { parseDateTime } from './common';
import { parseConversationAttachmentEntity } from './ConversationAttachmentEntity';
import { parseConversationMessageEntity } from './ConversationMessageEntity';
export function parseConversationEntity(data) {
    var _a, _b;
    if (!data) {
        return null;
    }
    return Object.assign(Object.assign({}, data), { DateTimeCreated: parseDateTime(data.DateTimeCreated), DateTimeModified: parseDateTime(data.DateTimeModified), Attachments: (_a = data.Attachments) === null || _a === void 0 ? void 0 : _a.map(itm => parseConversationAttachmentEntity(itm)), Communications: (_b = data.Communications) === null || _b === void 0 ? void 0 : _b.map(itm => parseConversationMessageEntity(itm)) });
}
