import { ClientDelegate } from '../ClientDelegate';
import { parseCollection } from '../../common';
import { parseDeliveryEntity } from '../../entity';
export class SettingsDelegate extends ClientDelegate {
    async deliveries() {
        const response = await this._client.get(`v2/shop/deliverymethods`);
        return parseCollection(response.data, parseDeliveryEntity);
    }
    async closingDays(options) {
        const response = await this._client.get(`v2/closingDays`, {
            params: options
        });
        return response.data;
    }
    async core() {
        const response = await this._client.get(`v2/shop/core/settings`);
        return response.data;
    }
}
