import { ClientDelegate } from '../ClientDelegate';
import { parseSerialAttachmentEntity, parseSerialEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/serials';
export class SerialsDelegate extends ClientDelegate {
    async find(serial) {
        const response = await this._client.get(`v2/${PATH}/${serial}`);
        const result = response.data;
        return parseSerialEntity(result);
    }
    async attachments(serial) {
        const response = await this._client.get(`v2/${PATH}/${serial}/attachments`);
        return parseCollection(response.data, parseSerialAttachmentEntity);
    }
}
