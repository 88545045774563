import { ClientDelegate } from '../ClientDelegate';
import { parseInvoiceEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/invoices';
export class InvoicesDelegate extends ClientDelegate {
    async all(customerId, options) {
        const response = await this._client.get(`v2/${PATH}`, {
            params: Object.assign(Object.assign({ customerID: customerId }, (options || {})), { includeContactId: false, includeCustomerId: true }),
        });
        return parseCollection(response.data, parseInvoiceEntity, options);
    }
    async pdfData(invoiceId) {
        const response = await this._client.get(`v2/${PATH}/${invoiceId}/_pdf`, {
            params: {
                includeContactId: false,
                includeCustomerId: true
            }
        });
        return response.data;
    }
}
