import { ClientDelegate } from '../ClientDelegate';
import { parseItemEntity, parseItemPriceEntity, parseItemStockEntity, } from '../../entity';
import { transformDate } from '../../input/common';
import { parseCollection } from '../../common';
const PATH = 'shop/items';
export function transformItemDataOptions(input) {
    return {
        DateTimeExpectedStart: transformDate(input === null || input === void 0 ? void 0 : input.DateTimeExpectedStart),
        DateTimeExpectedEnd: transformDate(input === null || input === void 0 ? void 0 : input.DateTimeExpectedEnd),
        contactID: input === null || input === void 0 ? void 0 : input.contactID,
        warehouse: input === null || input === void 0 ? void 0 : input.warehouse,
        CustomerID: input === null || input === void 0 ? void 0 : input.CustomerID,
        amount: input === null || input === void 0 ? void 0 : input.amount,
    };
}
export class ItemsDelegate extends ClientDelegate {
    async all(options) {
        let params = Object.assign({}, options);
        delete params['properties'];
        if (options.properties) {
            for (let [property, values] of Object.entries(options.properties)) {
                if (!values.length) {
                    continue;
                }
                params[`properties[${property}]`] = values.join(',');
            }
        }
        const response = await this._client.get(`v2/${PATH}`, {
            params: Object.assign(Object.assign({}, params), { includeContactId: false, includeCustomerId: true }),
        });
        return parseCollection(response.data, parseItemEntity);
    }
    async find(itemId, options) {
        const response = await this._client.get(`v2/${PATH}/${itemId}`, {
            params: Object.assign(Object.assign({}, options), { includeContactId: true, includeCustomerId: true }),
        });
        const result = response.data;
        return parseItemEntity(result);
    }
    async suggested(itemId) {
        const response = await this._client.get(`v2/${PATH}/${itemId}/suggested`, {
            params: {
                includeContactId: false,
                includeCustomerId: true
            }
        });
        return parseCollection(response.data, parseItemEntity);
    }
    async stock(itemId, options) {
        const response = await this._client.get(`v2/${PATH}/${itemId}/stock`, {
            params: Object.assign(Object.assign({}, transformItemDataOptions(options)), { includeContactId: true, includeCustomerId: true }),
        });
        const result = response.data;
        return parseItemStockEntity(result);
    }
    async price(itemId, options) {
        const params = transformItemDataOptions(options);
        params.DateTimeBusinessStart = params.DateTimeExpectedStart;
        params.DateTimeBusinessEnd = params.DateTimeExpectedEnd;
        const response = await this._client.get(`v2/${PATH}/${itemId}/price`, {
            params: Object.assign(Object.assign({}, params), { includeContactId: true, includeCustomerId: true }),
        });
        const result = response.data;
        return parseItemPriceEntity(result);
    }
}
