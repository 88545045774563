export function transformIncludeOptions(input) {
    return {
        include: (input === null || input === void 0 ? void 0 : input.include) ? input.include.join(',') : null
    };
}
export function parseCollection(input, itemParser, options) {
    if (!input) {
        return null;
    }
    if (options === null || options === void 0 ? void 0 : options.format) {
        return input;
    }
    return Object.assign(Object.assign({}, input), { Collection: input.Collection.map(item => itemParser(item)) });
}
export async function resolveProvider(provider) {
    if (provider instanceof Function) {
        return await provider();
    }
    else {
        return provider;
    }
}
export function prefixObject(object, prefix) {
    if (!object) {
        return null;
    }
    let result = {};
    for (let key of Object.keys(object)) {
        result[`${prefix}_${key}`] = object[key];
    }
    return result;
}
export function compactObject(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}
