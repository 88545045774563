export function parseDate(input) {
    if (!input) {
        return null;
    }
    const [dateString] = input.split('T');
    return dateString;
}
export function parseDateTime(input) {
    if (!input) {
        return null;
    }
    return input;
}
