<template>
    <a href="#">
        <slot />
    </a>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';


@Component({
    name: 'router-link'
})
export default class RouterLinkShim extends Vue {

}
</script>
