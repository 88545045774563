export var Country;
(function (Country) {
    Country["AD"] = "AD";
    Country["AE"] = "AE";
    Country["AF"] = "AF";
    Country["AG"] = "AG";
    Country["AI"] = "AI";
    Country["AL"] = "AL";
    Country["AM"] = "AM";
    Country["AN"] = "AN";
    Country["AO"] = "AO";
    Country["AQ"] = "AQ";
    Country["AR"] = "AR";
    Country["AS"] = "AS";
    Country["AT"] = "AT";
    Country["AU"] = "AU";
    Country["AW"] = "AW";
    Country["AX"] = "AX";
    Country["AZ"] = "AZ";
    Country["BA"] = "BA";
    Country["BB"] = "BB";
    Country["BD"] = "BD";
    Country["BE"] = "BE";
    Country["BF"] = "BF";
    Country["BG"] = "BG";
    Country["BH"] = "BH";
    Country["BI"] = "BI";
    Country["BJ"] = "BJ";
    Country["BL"] = "BL";
    Country["BM"] = "BM";
    Country["BN"] = "BN";
    Country["BO"] = "BO";
    Country["BQ"] = "BQ";
    Country["BR"] = "BR";
    Country["BS"] = "BS";
    Country["BT"] = "BT";
    Country["BV"] = "BV";
    Country["BW"] = "BW";
    Country["BY"] = "BY";
    Country["BZ"] = "BZ";
    Country["CA"] = "CA";
    Country["CC"] = "CC";
    Country["CD"] = "CD";
    Country["CF"] = "CF";
    Country["CG"] = "CG";
    Country["CH"] = "CH";
    Country["CI"] = "CI";
    Country["CK"] = "CK";
    Country["CL"] = "CL";
    Country["CM"] = "CM";
    Country["CN"] = "CN";
    Country["CO"] = "CO";
    Country["CR"] = "CR";
    Country["CU"] = "CU";
    Country["CV"] = "CV";
    Country["CW"] = "CW";
    Country["CX"] = "CX";
    Country["CY"] = "CY";
    Country["CZ"] = "CZ";
    Country["DE"] = "DE";
    Country["DJ"] = "DJ";
    Country["DM"] = "DM";
    Country["DO"] = "DO";
    Country["DZ"] = "DZ";
    Country["EC"] = "EC";
    Country["EE"] = "EE";
    Country["EG"] = "EG";
    Country["EH"] = "EH";
    Country["ER"] = "ER";
    Country["ES"] = "ES";
    Country["ET"] = "ET";
    Country["FI"] = "FI";
    Country["FJ"] = "FJ";
    Country["FK"] = "FK";
    Country["FM"] = "FM";
    Country["FO"] = "FO";
    Country["FR"] = "FR";
    Country["GA"] = "GA";
    Country["GB"] = "GB";
    Country["GD"] = "GD";
    Country["GE"] = "GE";
    Country["GF"] = "GF";
    Country["GG"] = "GG";
    Country["GH"] = "GH";
    Country["GI"] = "GI";
    Country["GL"] = "GL";
    Country["GM"] = "GM";
    Country["GN"] = "GN";
    Country["GP"] = "GP";
    Country["GQ"] = "GQ";
    Country["GR"] = "GR";
    Country["GS"] = "GS";
    Country["GT"] = "GT";
    Country["GU"] = "GU";
    Country["GW"] = "GW";
    Country["GY"] = "GY";
    Country["HK"] = "HK";
    Country["HM"] = "HM";
    Country["HN"] = "HN";
    Country["HR"] = "HR";
    Country["HT"] = "HT";
    Country["HU"] = "HU";
    Country["ID"] = "ID";
    Country["IE"] = "IE";
    Country["IL"] = "IL";
    Country["IM"] = "IM";
    Country["IN"] = "IN";
    Country["IO"] = "IO";
    Country["IQ"] = "IQ";
    Country["IR"] = "IR";
    Country["IS"] = "IS";
    Country["IT"] = "IT";
    Country["JE"] = "JE";
    Country["JM"] = "JM";
    Country["JO"] = "JO";
    Country["JP"] = "JP";
    Country["KE"] = "KE";
    Country["KG"] = "KG";
    Country["KH"] = "KH";
    Country["KI"] = "KI";
    Country["KM"] = "KM";
    Country["KN"] = "KN";
    Country["KP"] = "KP";
    Country["KR"] = "KR";
    Country["KW"] = "KW";
    Country["KY"] = "KY";
    Country["KZ"] = "KZ";
    Country["LA"] = "LA";
    Country["LB"] = "LB";
    Country["LC"] = "LC";
    Country["LI"] = "LI";
    Country["LK"] = "LK";
    Country["LR"] = "LR";
    Country["LS"] = "LS";
    Country["LT"] = "LT";
    Country["LU"] = "LU";
    Country["LV"] = "LV";
    Country["LY"] = "LY";
    Country["MA"] = "MA";
    Country["MC"] = "MC";
    Country["MD"] = "MD";
    Country["ME"] = "ME";
    Country["MF"] = "MF";
    Country["MG"] = "MG";
    Country["MH"] = "MH";
    Country["MK"] = "MK";
    Country["ML"] = "ML";
    Country["MM"] = "MM";
    Country["MN"] = "MN";
    Country["MO"] = "MO";
    Country["MP"] = "MP";
    Country["MQ"] = "MQ";
    Country["MR"] = "MR";
    Country["MS"] = "MS";
    Country["MT"] = "MT";
    Country["MU"] = "MU";
    Country["MV"] = "MV";
    Country["MW"] = "MW";
    Country["MX"] = "MX";
    Country["MY"] = "MY";
    Country["MZ"] = "MZ";
    Country["NA"] = "NA";
    Country["NC"] = "NC";
    Country["NE"] = "NE";
    Country["NF"] = "NF";
    Country["NG"] = "NG";
    Country["NI"] = "NI";
    Country["NL"] = "NL";
    Country["NO"] = "NO";
    Country["NP"] = "NP";
    Country["NR"] = "NR";
    Country["NU"] = "NU";
    Country["NZ"] = "NZ";
    Country["OM"] = "OM";
    Country["PA"] = "PA";
    Country["PE"] = "PE";
    Country["PF"] = "PF";
    Country["PG"] = "PG";
    Country["PH"] = "PH";
    Country["PK"] = "PK";
    Country["PL"] = "PL";
    Country["PM"] = "PM";
    Country["PN"] = "PN";
    Country["PR"] = "PR";
    Country["PS"] = "PS";
    Country["PT"] = "PT";
    Country["PW"] = "PW";
    Country["PY"] = "PY";
    Country["QA"] = "QA";
    Country["RE"] = "RE";
    Country["RO"] = "RO";
    Country["RS"] = "RS";
    Country["RU"] = "RU";
    Country["RW"] = "RW";
    Country["SA"] = "SA";
    Country["SB"] = "SB";
    Country["SC"] = "SC";
    Country["SD"] = "SD";
    Country["SE"] = "SE";
    Country["SG"] = "SG";
    Country["SH"] = "SH";
    Country["SI"] = "SI";
    Country["SJ"] = "SJ";
    Country["SK"] = "SK";
    Country["SL"] = "SL";
    Country["SM"] = "SM";
    Country["SN"] = "SN";
    Country["SO"] = "SO";
    Country["SR"] = "SR";
    Country["SS"] = "SS";
    Country["ST"] = "ST";
    Country["SV"] = "SV";
    Country["SX"] = "SX";
    Country["SY"] = "SY";
    Country["SZ"] = "SZ";
    Country["TC"] = "TC";
    Country["TD"] = "TD";
    Country["TF"] = "TF";
    Country["TG"] = "TG";
    Country["TH"] = "TH";
    Country["TJ"] = "TJ";
    Country["TK"] = "TK";
    Country["TL"] = "TL";
    Country["TM"] = "TM";
    Country["TN"] = "TN";
    Country["TO"] = "TO";
    Country["TR"] = "TR";
    Country["TT"] = "TT";
    Country["TV"] = "TV";
    Country["TW"] = "TW";
    Country["TZ"] = "TZ";
    Country["UA"] = "UA";
    Country["UG"] = "UG";
    Country["UM"] = "UM";
    Country["US"] = "US";
    Country["UY"] = "UY";
    Country["UZ"] = "UZ";
    Country["VA"] = "VA";
    Country["VC"] = "VC";
    Country["VE"] = "VE";
    Country["VG"] = "VG";
    Country["VI"] = "VI";
    Country["VN"] = "VN";
    Country["VU"] = "VU";
    Country["WF"] = "WF";
    Country["WS"] = "WS";
    Country["XK"] = "XK";
    Country["YE"] = "YE";
    Country["YT"] = "YT";
    Country["ZA"] = "ZA";
    Country["ZM"] = "ZM";
    Country["ZW"] = "ZW";
})(Country || (Country = {}));
