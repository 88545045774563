import { ClientDelegate } from '../ClientDelegate';
import { parseWarehouseEntity } from '../../entity';
import { parseCollection } from '../../common';
const PATH = 'shop/warehouse';
export class WarehousesDelegate extends ClientDelegate {
    async all() {
        const response = await this._client.get(`v2/${PATH}`);
        return parseCollection(response.data, parseWarehouseEntity);
    }
    async shipmentHours(warehouseId, date) {
        const response = await this._client.get(`v2/warehouse/${warehouseId}/businessdays/${date}/shipmenthours`);
        return response.data;
    }
    async returnHours(warehouseId, date) {
        const response = await this._client.get(`v2/warehouse/${warehouseId}/businessdays/${date}/returnhours`);
        return response.data;
    }
}
